import "../styles/cv.css"

import Head from "../components/head";
import Nav from "../components/nav";
import Headline from "../components/headline";
import Footer from "../components/footer";

import cvimg from "../assets/cv/cv-jorma-bissinger-24-03-25.png";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react'

function Cv(props) {
    const lenis = useLenis(({ scroll }) => {
        // called every scroll
      })
    return (
        <ReactLenis root>

            <Head/>

            <Nav active={props.active} />
            
            <div className="cvWrapper">
                <img src={cvimg} />
            </div>

            <Footer link="/home" linktext="Zum Portfolio"/>

        </ReactLenis>
    )
}

export default Cv;