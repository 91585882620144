import "../styles/bal.css";

import LinkBack from "../components/link-back";
import Link from "../components/link";

import limefront from "../assets/webproject/lime/limefront.webp";
import lime1 from "../assets/webproject/lime/lime-1.svg";
import lime2 from "../assets/webproject/lime/lime-2.svg";
import lime3 from "../assets/webproject/lime/lime-3.svg";
import lime4 from "../assets/webproject/lime/lime-4.svg";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';

function Lime(props) {
    const lenis = useLenis(({ scroll }) => {})
    
    document.body.style.backgroundColor = "var(--cactus)";
    document.body.style.setProperty("--scrollbar-background", "var(--violet)");
    document.body.style.setProperty("--scrollbar-border", "var(--violet)");

    useEffect(() => {
        window.scroll(0, 0)
    })
    
    return <ReactLenis root>
        <div className="bal">
            <LinkBack link="/home">Zurück</LinkBack>

            <div className="pGrid">
                <div className="pHead" style={{
                    backgroundColor: "var(--cactus)"
                }}>
                    <h3>Limeboard</h3>
                    <h4>Web Application</h4>
                </div>
                <div className="pTextWrapper" style={{
                    backgroundColor: "var(--cactus)"
                }}>
                    <p>Limeboard ist eine web-basierte Planungssoftware für Feiern, Firmenevents, Fortbildungen, Schulveranstaltungen und mehr. Innerhalb eines sogenannten Boards können mehrere Events sowie jeweilige Eckdaten verwaltet und ansprechend dargestellt werden. Dafür sorgen das übersichtliche Dashboard und verschiedene View-Modi.<br/><br/>Stil: modern, schlicht, verspielt, auffällige Farben<br/><br/>Mein Aufgabenbereich: Konzept, UI-Design & Prototype</p>
                    <Link link=""/>
                </div>
                <div className="pFrontImg" style={{
                    background: "url('" + limefront + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + lime1 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + lime2 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + lime3 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + lime4 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

            </div>

            <div className="pNextLink">
                <Link link="/3" >Zum nächsten Projekt</Link>
            </div>

        </div>
    </ReactLenis>
}

export default Lime;