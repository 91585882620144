import "../styles/navitem.css"

function NavItem(props) {
    return <div className={"navItem" + (props.active ? " active" : "")}
    
        onClick={() => {
            window.open(props.link, "_self");
        }}
    >
        <div className="navItemWrapper">
            <h6>{props.children}</h6>
            <hr/>
        </div>
    </div>;
}

export default NavItem;