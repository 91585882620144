import "../styles/bal.css";

import LinkBack from "../components/link-back";
import Link from "../components/link";

import cdxfront from "../assets/webproject/cdx/cdxfront.webp";
import cdx1 from "../assets/webproject/cdx/cdx-1.webp";
import cdx2 from "../assets/webproject/cdx/cdx-2.webp";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';

function Cdx(props) {
    const lenis = useLenis(({ scroll }) => {})
    
    document.body.style.backgroundColor = "var(--silver)";
    document.body.style.setProperty("--scrollbar-background", "var(--violet)");
    document.body.style.setProperty("--scrollbar-border", "var(--violet)");

    useEffect(() => {
        window.scroll(0, 0)
    })

    return <ReactLenis root>
        <div className="bal">
            <LinkBack link="/home">Zurück</LinkBack>

            <div className="pGrid">
                <div className="pHead" style={{
                    backgroundColor: "var(--silver)"
                }}>
                    <h3>Cuodex</h3>
                    <h4>HOMEPAGE</h4>
                </div>
                <div className="pTextWrapper" style={{
                    backgroundColor: "var(--silver)"
                }}>
                    <p>Cuodex ist ein Software-Projekt von Schülern und Studenten, mit dem Ziel simple, innovative und zugängliche Software-Lösungen zu schaffen (und dabei noch etwas zu lernen). Auf der Hauptseite findet man allgemeine Informationen über Hintergründe und die Philosophie des Projekts, auf den Unterseiten Vorstellungen der einzelnen Applikationen.<br/><br/>Die Seite soll modern, aufgeräumt und außergewöhnlich wirken.<br/><br/>Mein Aufgabenbereich: UI-Design & Prototype

</p>
                    <Link link="https://cuodex.net" >Zur Website</Link>
                </div>
                <div className="pFrontImg" style={{
                    background: "url('" + cdxfront + "') no-repeat right center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15 pFullHeight" style={{
                    background: "var(--black-sand) url('" + cdx1 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15 pFullHeight" style={{
                    background: "var(--black-sand) url('" + cdx2 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

            </div>

            <div className="pNextLink">
                <Link link="/1" >Zum ersten Projekt</Link>
            </div>

        </div>
    </ReactLenis>
}

export default Cdx;