import "../styles/webProject.css"

import Link from "./link";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";

gsap.registerPlugin(ScrollTrigger);

function WebProject(props) {
    return <div className="webProject" style={{
        backgroundColor: "var(--" + props.color + ")"
    }}>
        <div className="imgWrapper">
            <img className="projectImg" src={props.image} />
        </div>
        <div className="titleWrapper">
            <h3>{props.children}</h3>
            <h4>{props.subhead}</h4>
        </div>
        <div className="buttonWrapper">
            <Link link={props.moreLink} >Mehr</Link>
            <Link link={props.websiteLink} >Zur Website</Link>
        </div>
    </div>;
}

export default WebProject;