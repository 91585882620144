import {BrowserRouter, Routes, Route, Navigate, useLocation} from "react-router-dom";
import "./styles/main.css"
import Home from "./pages/home";
import Cv from "./pages/cv";
import Bal from "./pages/bal";
import Lime from "./pages/lime";
import Jjb from "./pages/jjb";
import Sol from "./pages/sol";
import Cdx from "./pages/cdx";

import { gsap } from "gsap"; 
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

gsap.registerPlugin(ScrollTrigger);


function App() {
  const [scrollY, setScrollY] = useState(0);
  //console.log(scrollY);
  useEffect(() => {
    
    window.addEventListener("updateScrollY", (e) => {
      setScrollY(e.detail.value);
    })
  })
  
  /* const comp = useRef(null)

    useLayoutEffect(() => {
      var h4 = gsap.utils.toArray(document.getElementsByTagName('h4'));
        h4.forEach(el => {
          let ctx = gsap.context(() => {
            const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom",
                    toggleActions: "play none none reset"
                    }
            })
            t1.from(el, {
                x: -20,
                width: 0,
                duration: .5
            })
        }, comp)

        return () => ctx.revert()
        })
        
    }, []) */
  return <BrowserRouter>
    <Routes>
      <Route path="home" element={<Home active={0} scrollY={scrollY} />}/>
      <Route path="cv" element={<Cv active={1} />}/>
      <Route path="1" element={<Bal/>} />
      <Route path="2" element={<Lime/>} />
      <Route path="3" element={<Jjb/>} />
      <Route path="4" element={<Sol/>} />
      <Route path="5" element={<Cdx/>} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  </BrowserRouter>;
}

export default App;
