import "../styles/link-back.css";

import linkarrow from "../assets/general/linkarrow.svg";
import { useNavigate } from "react-router-dom";

function LinkBack(props) {
    const navigate = useNavigate();
    return <div onClick={() => {
        if(!props.link) return;
        if((["https://", "mailto:"].some(x => props.link.includes(x)))){
            window.open(props.link, "_blank");
        }else{
            navigate(props.link);
        }
    }} className="linkBack" style={{
        display: props.link ? "block" : "none"
    }}>
        <h4> <img id="linkBackArrow" src={linkarrow} /> {props.children} </h4>
        
    </div>;
}

export default LinkBack;