import "../styles/concept.css";

function Concept(props) {
    return <div className="concept">
        <div className="imgWrapper" style={{
            background: "url('" + props.image + "') no-repeat center",
            backgroundSize: "cover"
        }}/>
        <div className="textWrapper">
            <h5>{props.headline}</h5>
            <p>{props.children}</p>
        </div>
    </div>
}

export default Concept;