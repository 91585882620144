import "../styles/bal.css";

import LinkBack from "../components/link-back";
import Link from "../components/link";

import balfront from "../assets/webproject/bal/bal-frontimg.webp";
import bal1 from "../assets/webproject/bal/bal-1.webp";
import bal3 from "../assets/webproject/bal/bal-3.webp";
import bal4 from "../assets/webproject/bal/bal-4.webp";
import bal5 from "../assets/webproject/bal/bal-5.webp";
import bal6 from "../assets/webproject/bal/bal-6.webp";
import bal7 from "../assets/webproject/bal/bal-7.webp";
import bal8 from "../assets/webproject/bal/bal-8.webp";
import bal9 from "../assets/webproject/bal/bal-9.webp";
import bal10 from "../assets/webproject/bal/bal-10.webp";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';

function Bal(props) {
    const lenis = useLenis(({ scroll }) => {})
    
    document.body.style.backgroundColor = "var(--violet)";
    document.body.style.setProperty("--scrollbar-background", "var(--violet)");
    document.body.style.setProperty("--scrollbar-border", "var(--violet)");

    useEffect(() => {
        window.scroll(0, 0)
    })

    return <ReactLenis root>
        <div className="bal">
            <LinkBack link="/home">Zurück</LinkBack>

            <div className="pGrid">
                <div className="pHead" style={{
                    backgroundColor: "var(--violet)"
                }}>
                    <h3>BERGAMLAIMER.INFO</h3>
                    <h4>HOMEPAGE & DASHBOARD</h4>
                </div>
                <div className="pTextWrapper" style={{
                    backgroundColor: "var(--violet)"
                }}>
                    <p>Bergamlaimer.info ist ein digitaler Treffpunkt für den Stadtteil im Münchner Osten. Die Seite bietet Platz für Vereine, informiert über aktuelle Geschehnisse und Termine und verfügt über eine Helferbörse. Zudem haben wir für die komplexen dynamischen Inhalte, die von Personen mit unterschiedlichen Berechtigungen bearbeitet werden müssen, ein eigenes CMS mit Dashboard umgesetzt.<br/><br/>Mein Aufgabenbereich: Konzeption, UI-Design & Grafik</p>
                    <Link link="https://bergamlaimer.info" >Zur Website</Link>
                </div>
                <div className="pFrontImg" style={{
                    background: "url('" + balfront + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal1 + "') no-repeat top center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal1 + "') no-repeat bottom center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "#58556F url('" + bal3 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "#58556F url('" + bal4 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal5 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal6 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal7 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal8 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal9 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + bal10 + "') no-repeat center center",
                    backgroundSize: "contain"
                }}/>

            </div>

            <div className="pNextLink">
                <Link link="/2" >Zum nächsten Projekt</Link>
            </div>

        </div>
    </ReactLenis>
}

export default Bal;