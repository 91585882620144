import "../styles/gallery.css";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";

import photo1 from "../assets/photographs/kohlmeise.jpg";
import photo2 from "../assets/photographs/gesang.jpg";
import photo3 from "../assets/photographs/spring.jpg";

import photo4 from "../assets/photographs/schmetter.jpg";
import photo5 from "../assets/photographs/gelbeblumen.jpg";
import photo6 from "../assets/photographs/osterglocke.jpg";

import photo7 from "../assets/photographs/jeanluc.webp";
import photo8 from "../assets/photographs/kirschblueten.jpg";
import photo9 from "../assets/photographs/rotkehl.jpg";

import photo10 from "../assets/photographs/flight.jpg";
import photo11 from "../assets/photographs/yacht.jpg";
import photo12 from "../assets/photographs/sunset.jpg";

gsap.registerPlugin(ScrollTrigger);


function Gallery(props) {
    const comp = useRef(null)

    useLayoutEffect(() => {
        let el1 = document.getElementsByClassName('anim1');
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: el1,
                    start: "top bottom+=200px",
                    scrub: true
                    }
            })
            t1.fromTo(el1, {
                stagger: 1.5,
                y: "5vw",
                duration: 6
            },{
                y: "-30vw",
                stagger: 1.5,
                duration: 6
            })
        }, comp)

        return () => ctx.revert()
    }, [])

    useLayoutEffect(() => {
        let el2 = document.getElementsByClassName('anim2');
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: el2,
                    start: "top bottom+=200px",
                    scrub: true
                    }
            })
            t1.fromTo(el2, {
                stagger: 1.5,
                y: "5vw",
                duration: 6
            },{
                y: "-30vw",
                stagger: 1.5,
                duration: 6
            })
        }, comp)

        return () => ctx.revert()
    }, [])

    useLayoutEffect(() => {
        let el3 = document.getElementsByClassName('anim3');
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: el3,
                    start: "top bottom+=200px",
                    scrub: true
                    }
            })
            t1.fromTo(el3, {
                stagger: .5,
                y: "5vw",
                duration: 6
            },{
                y: "-30vw",
                stagger: 1.5,
                duration: 6
            })
        }, comp)

        return () => ctx.revert()
    }, [])

    useLayoutEffect(() => {
        let el4 = document.getElementsByClassName('anim4');
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline({
                scrollTrigger: {
                    trigger: el4,
                    start: "top bottom+=200px",
                    scrub: true
                    }
            })
            t1.fromTo(el4, {
                stagger: 1.5,
                y: "5vw",
                duration: 6
            },{
                y: "-30vw",
                stagger: 1.5,
                duration: 6
            })
        }, comp)

        return () => ctx.revert()
    }, [])
    
    return <div className="gallery">
        <div className="gallery-img-container anim1"><img className="img" src={photo1} /></div>
        <div className="gallery-img-container anim1"><img className="img" src={photo2} /></div>
        <div className="gallery-img-container anim1"><img className="img" src={photo3} /></div>
        <div className="gallery-img-container anim2"><img className="img" src={photo4} /></div>
        <div className="gallery-img-container anim2"><img className="img" src={photo5} /></div>
        <div className="gallery-img-container anim2"><img className="img" src={photo6} /></div>
        <div className="gallery-img-container anim3"><img className="img" src={photo7} /></div>
        <div className="gallery-img-container anim3"><img className="img" src={photo8} /></div>
        <div className="gallery-img-container anim3"><img className="img" src={photo9} /></div>
        <div className="gallery-img-container anim4"><img className="img" src={photo10} /></div>
        <div className="gallery-img-container anim4"><img className="img" src={photo11} /></div>
        <div className="gallery-img-container anim4"><img className="img" src={photo12} /></div>
    </div>
}

export default Gallery;