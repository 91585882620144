import "../styles/link.css";

import linkarrow from "../assets/general/linkarrow.svg";
import { useNavigate } from "react-router-dom";

function Link(props) {
    const navigate = useNavigate();
    return <div onClick={() => {
        if(!props.link) return;
        if((["https://", "mailto:"].some(x => props.link.includes(x)))){
            window.open(props.link, "_blank");
        }else if((["/cv", "jorma.cuodex.net/home"].some(x => props.link.includes(x)))){
            window.open(props.link, "_self");
        }else{
            navigate(props.link);
        }
    }} className="link" style={{
        display: props.link ? "block" : "none"
    }}>
        <h4>{props.children} &nbsp; <img id="linkarrow" src={linkarrow} /> </h4>
        
    </div>;
}

export default Link;