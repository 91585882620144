import "../styles/nav.css"
import NavItem from "./navitem";

function Nav(props) {
    return <div className="nav">
        <NavItem link="/home" active={props.active==0}>Portfolio</NavItem>
        <NavItem link="/cv" active={props.active==1}>Lebenslauf</NavItem>
    </div>;
}

export default Nav;