import "../styles/footer.css"

import Link from "../components/link";
import Headline from "../components/headline";

function Footer(props) {
    return <div className="footer">
        <Headline>Kontakt</Headline>
        <Link link="mailto:jorma.bissinger@gmail.com" id="linkmailto" >Jorma.bissinger<wbr/>@gmail.com</Link>
        <hr className="footerHr"/>
        <Link link={props.link} >{props.linktext}</Link>
    </div>;
}

export default Footer;